export enum UserKycStatus {
	None = 0,
	Review = 1,
	Rejected = 2,
	Verified = 3,
}

export enum EscrowType {
	None,
	Banking,
	BankCheck,
	ReceiveUSDT,
	ReceiveVPC,
}

export const STEP = [
	{
		title: 'kyc-form.step1.title',
		key: ['FirstName', 'Middle', 'LastName','PhonePersonal','EmailPersonal', 'DateOfBirth', 'Address', 'Country', 'State', 'ZipCode', 'City'],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step2.title',
		key: ['DocumentType', 'IdentityCardFront','UtilityBillType', 'UtilityBillFront' ],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'kyc-form.step3.title',
		key: [
			'BusinessName',
			'BusinessType',
			'BusinessWebsite',
			'BusinessAddress',
			'BusinessCity',
			'BusinessState',
			'BusinessZipCode',
			'BusinessCountry',
			'PhoneNumber',
			'Email',
			'BusinessRegistrationType',
			'BusinessRegistrationImage'
		],
		items: [],
		hasItemInvalid: false,
	},
	{
		title: 'location',
		key: ['Longitude', 'Latitude'],
		items: [],
		hasItemInvalid: false,
	},
];

export const DIAL_CODE = [
	{
		country: 'United States',
		code: '+1',
		states: [
		  { name: 'Alabama' },
		  { name: 'Alaska' },
		  { name: 'Arizona' },
		  { name: 'Arkansas' },
		  { name: 'California' },
		  { name: 'Colorado' },
		  { name: 'Connecticut' },
		  { name: 'Delaware' },
		  { name: 'Florida' },
		  { name: 'Georgia' },
		  { name: 'Hawaii' },
		  { name: 'Idaho' },
		  { name: 'Illinois' },
		  { name: 'Indiana' },
		  { name: 'Iowa' },
		  { name: 'Kansas' },
		  { name: 'Kentucky' },
		  { name: 'Louisiana' },
		  { name: 'Maine' },
		  { name: 'Maryland' },
		  { name: 'Massachusetts' },
		  { name: 'Michigan' },
		  { name: 'Minnesota' },
		  { name: 'Mississippi' },
		  { name: 'Missouri' },
		  { name: 'Montana' },
		  { name: 'Nebraska' },
		  { name: 'Nevada' },
		  { name: 'New Hampshire' },
		  { name: 'New Jersey' },
		  { name: 'New Mexico' },
		  { name: 'New York' },
		  { name: 'North Carolina' },
		  { name: 'North Dakota' },
		  { name: 'Ohio' },
		  { name: 'Oklahoma' },
		  { name: 'Oregon' },
		  { name: 'Pennsylvania' },
		  { name: 'Rhode Island' },
		  { name: 'South Carolina' },
		  { name: 'South Dakota' },
		  { name: 'Tennessee' },
		  { name: 'Texas' },
		  { name: 'Utah' },
		  { name: 'Vermont' },
		  { name: 'Virginia' },
		  { name: 'Washington' },
		  { name: 'West Virginia' },
		  { name: 'Wisconsin' },
		  { name: 'Wyoming' },
		  { name: 'District of Columbia' } 
		]
	  },
	{
		country: 'Vietnam',
		code: '+84',
		states: [
		  { name: 'Hà Nội' },
		  { name: 'Hồ Chí Minh' },
		  { name: 'Đà Nẵng' },
		  { name: 'Hải Phòng' },
		  { name: 'Cần Thơ' },
		  { name: 'An Giang' },
		  { name: 'Bà Rịa - Vũng Tàu' },
		  { name: 'Bắc Giang' },
		  { name: 'Bắc Kạn' },
		  { name: 'Bạc Liêu' },
		  { name: 'Bắc Ninh' },
		  { name: 'Bến Tre' },
		  { name: 'Bình Định' },
		  { name: 'Bình Dương' },
		  { name: 'Bình Phước' },
		  { name: 'Bình Thuận' },
		  { name: 'Cà Mau' },
		  { name: 'Cao Bằng' },
		  { name: 'Đắk Lắk' },
		  { name: 'Đắk Nông' },
		  { name: 'Điện Biên' },
		  { name: 'Đồng Nai' },
		  { name: 'Đồng Tháp' },
		  { name: 'Gia Lai' },
		  { name: 'Hà Giang' },
		  { name: 'Hà Nam' },
		  { name: 'Hà Tĩnh' },
		  { name: 'Hải Dương' },
		  { name: 'Hậu Giang' },
		  { name: 'Hòa Bình' },
		  { name: 'Hưng Yên' },
		  { name: 'Khánh Hòa' },
		  { name: 'Kiên Giang' },
		  { name: 'Kon Tum' },
		  { name: 'Lai Châu' },
		  { name: 'Lâm Đồng' },
		  { name: 'Lạng Sơn' },
		  { name: 'Lào Cai' },
		  { name: 'Long An' },
		  { name: 'Nam Định' },
		  { name: 'Nghệ An' },
		  { name: 'Ninh Bình' },
		  { name: 'Ninh Thuận' },
		  { name: 'Phú Thọ' },
		  { name: 'Phú Yên' },
		  { name: 'Quảng Bình' },
		  { name: 'Quảng Nam' },
		  { name: 'Quảng Ngãi' },
		  { name: 'Quảng Ninh' },
		  { name: 'Quảng Trị' },
		  { name: 'Sóc Trăng' },
		  { name: 'Sơn La' },
		  { name: 'Tây Ninh' },
		  { name: 'Thái Bình' },
		  { name: 'Thái Nguyên' },
		  { name: 'Thanh Hóa' },
		  { name: 'Thừa Thiên - Huế' },
		  { name: 'Tiền Giang' },
		  { name: 'Trà Vinh' },
		  { name: 'Tuyên Quang' },
		  { name: 'Vĩnh Long' },
		  { name: 'Vĩnh Phúc' },
		  { name: 'Yên Bái' }
		]
	  },
	  {
		country: 'Afghanistan',
		code: '+93',
		states: [
			{ name: 'Badakhshan' },
			{ name: 'Badghis' },
			{ name: 'Baghlan' },
			{ name: 'Balkh' },
			{ name: 'Bamyan' },
			{ name: 'Daykundi' },
			{ name: 'Farah' },
			{ name: 'Faryab' },
			{ name: 'Ghazni' },
			{ name: 'Ghor' },
			{ name: 'Helmand' },
			{ name: 'Herat' },
			{ name: 'Jowzjan' },
			{ name: 'Kabul' },
			{ name: 'Kandahar' },
			{ name: 'Kapisa' },
			{ name: 'Khost' },
			{ name: 'Kunar' },
			{ name: 'Kunduz' },
			{ name: 'Laghman' },
			{ name: 'Logar' },
			{ name: 'Maidan Wardak' },
			{ name: 'Nangarhar' },
			{ name: 'Nimroz' },
			{ name: 'Nuristan' },
			{ name: 'Paktia' },
			{ name: 'Paktika' },
			{ name: 'Panjshir' },
			{ name: 'Parwan' },
			{ name: 'Samangan' },
			{ name: 'Sar-e Pol' },
			{ name: 'Takhar' },
			{ name: 'Urozgan' },
			{ name: 'Zabul' }
		]
	  },
	  {
		country: 'Albania',
		code: '+355',
		states: [
			{ name: 'Berat' },
			{ name: 'Dibër' },
			{ name: 'Durrës' },
			{ name: 'Elbasan' },
			{ name: 'Fier' },
			{ name: 'Gjirokastër' },
			{ name: 'Korçë' },
			{ name: 'Kukës' },
			{ name: 'Lezhë' },
			{ name: 'Shkodër' },
			{ name: 'Tiranë' },
			{ name: 'Vlorë' }
		]
	  },
	  {
		country: 'Algeria',
		code: '+213',
		states: [
			{ name: 'Adrar' },
			{ name: 'Chlef' },
			{ name: 'Laghouat' },
			{ name: 'Oum El Bouaghi' },
			{ name: 'Batna' },
			{ name: 'Béjaïa' },
			{ name: 'Biskra' },
			{ name: 'Béchar' },
			{ name: 'Blida' },
			{ name: 'Bouira' },
			{ name: 'Tamanghasset' },
			{ name: 'Tébessa' },
			{ name: 'Tlemcen' },
			{ name: 'Tiaret' },
			{ name: 'Tizi Ouzou' },
			{ name: 'Algiers' },
			{ name: 'Djelfa' },
			{ name: 'Jijel' },
			{ name: 'Sétif' },
			{ name: 'Saïda' },
			{ name: 'Skikda' },
			{ name: 'Sidi Bel Abbès' },
			{ name: 'Annaba' },
			{ name: 'Guelma' },
			{ name: 'Constantine' },
			{ name: 'Médéa' },
			{ name: 'Mostaganem' },
			{ name: 'M`Sila' },
			{ name: 'Mascara' },
			{ name: 'Ouargla' },
			{ name: 'Oran' },
			{ name: 'El Bayadh' },
			{ name: 'Illizi' },
			{ name: 'Bordj Bou Arréridj' },
			{ name: 'Boumerdès' },
			{ name: 'El Tarf' },
			{ name: 'Tindouf' },
			{ name: 'Tissemsilt' },
			{ name: 'El Oued' },
			{ name: 'Khenchela' },
			{ name: 'Souk Ahras' },
			{ name: 'Tipaza' },
			{ name: 'Mila' },
			{ name: 'Aïn Defla' },
			{ name: 'Naâma' },
			{ name: 'Aïn Témouchent' },
			{ name: 'Ghardaïa' },
			{ name: 'Relizane' }
		]
	  },
	  {
		country: 'American Samoa',
		code: '+1-684',
		states: [
			{ name: 'Eastern District' },
			{ name: 'Western District' },
			{ name: 'Manuʻa District' },
			{ name: 'Rose Atoll' },
			{ name: 'Swains Island' }
		]
	  },
	  {
		country: 'Andorra',
		code: '+376',
		states: [
			{ name: 'Bengo' },
			{ name: 'Benguela' },
			{ name: 'Bié' },
			{ name: 'Cabinda' },
			{ name: 'Cuando Cubango' },
			{ name: 'Cuanza Norte' },
			{ name: 'Cuanza Sul' },
			{ name: 'Cunene' },
			{ name: 'Huambo' },
			{ name: 'Huíla' },
			{ name: 'Luanda' },
			{ name: 'Lunda Norte' },
			{ name: 'Lunda Sul' },
			{ name: 'Malanje' },
			{ name: 'Moxico' },
			{ name: 'Namibe' },
			{ name: 'Uíge' },
			{ name: 'Zaire' }
		]
	  },
	  {
		country: 'Angola',
		code: '+244',
		states: [
			{ name: 'Bengo' },
			{ name: 'Benguela' },
			{ name: 'Bié' },
			{ name: 'Cabinda' },
			{ name: 'Cuando Cubango' },
			{ name: 'Cuanza Norte' },
			{ name: 'Cuanza Sul' },
			{ name: 'Cunene' },
			{ name: 'Huambo' },
			{ name: 'Huíla' },
			{ name: 'Luanda' },
			{ name: 'Lunda Norte' },
			{ name: 'Lunda Sul' },
			{ name: 'Malanje' },
			{ name: 'Moxico' },
			{ name: 'Namibe' },
			{ name: 'Uíge' },
			{ name: 'Zaire' }
		]
	  },
	  {
		country: 'Argentina',
		code: '+54',
		states: [
			{ name: 'Buenos Aires' },
			{ name: 'Catamarca' },
			{ name: 'Chaco' },
			{ name: 'Chubut' },
			{ name: 'Córdoba' },
			{ name: 'Corrientes' },
			{ name: 'Entre Ríos' },
			{ name: 'Formosa' },
			{ name: 'Jujuy' },
			{ name: 'La Pampa' },
			{ name: 'La Rioja' },
			{ name: 'Mendoza' },
			{ name: 'Misiones' },
			{ name: 'Neuquén' },
			{ name: 'Río Negro' },
			{ name: 'Salta' },
			{ name: 'San Juan' },
			{ name: 'San Luis' },
			{ name: 'Santa Cruz' },
			{ name: 'Santa Fe' },
			{ name: 'Santiago del Estero' },
			{ name: 'Tierra del Fuego' },
			{ name: 'Tucumán' }
		]
	  },
	  {
		country: 'Australia',
		code: '+61',
		states: [
			{ name: 'New South Wales' },
			{ name: 'Victoria' },
			{ name: 'Queensland' },
			{ name: 'Western Australia' },
			{ name: 'South Australia' },
			{ name: 'Tasmania' },
			{ name: 'Australian Capital Territory' },
			{ name: 'Northern Territory' }
		]
	  },
	  {
		country: 'Brazil',
		code: '+55',
		states: [
			{ name: 'Acre' },
			{ name: 'Alagoas' },
			{ name: 'Amapá' },
			{ name: 'Amazonas' },
			{ name: 'Bahia' },
			{ name: 'Ceará' },
			{ name: 'Distrito Federal' },
			{ name: 'Espírito Santo' },
			{ name: 'Goiás' },
			{ name: 'Maranhão' },
			{ name: 'Mato Grosso' },
			{ name: 'Mato Grosso do Sul' },
			{ name: 'Minas Gerais' },
			{ name: 'Pará' },
			{ name: 'Paraíba' },
			{ name: 'Paraná' },
			{ name: 'Pernambuco' },
			{ name: 'Piauí' },
			{ name: 'Rio de Janeiro' },
			{ name: 'Rio Grande do Norte' },
			{ name: 'Rio Grande do Sul' },
			{ name: 'Rondônia' },
			{ name: 'Roraima' },
			{ name: 'Santa Catarina' },
			{ name: 'São Paulo' },
			{ name: 'Sergipe' },
			{ name: 'Tocantins' }
		]
	  },
	  {
		country: 'Canada',
		code: '+1',
		states: [
			{ name: 'Alberta' },
			{ name: 'British Columbia' },
			{ name: 'Manitoba' },
			{ name: 'New Brunswick' },
			{ name: 'Newfoundland and Labrador' },
			{ name: 'Nova Scotia' },
			{ name: 'Ontario' },
			{ name: 'Prince Edward Island' },
			{ name: 'Quebec' },
			{ name: 'Saskatchewan' },
			{ name: 'Northwest Territories' },
			{ name: 'Nunavut' },
			{ name: 'Yukon' }
		]
	  },
	  {
		country: 'China',
		code: '+86',
		states: [
			{ name: 'Anhui' },
			{ name: 'Fujian' },
			{ name: 'Gansu' },
			{ name: 'Guangdong' },
			{ name: 'Guizhou' },
			{ name: 'Hainan' },
			{ name: 'Hebei' },
			{ name: 'Heilongjiang' },
			{ name: 'Henan' },
			{ name: 'Hubei' },
			{ name: 'Hunan' },
			{ name: 'Jiangsu' },
			{ name: 'Jiangxi' },
			{ name: 'Jilin' },
			{ name: 'Liaoning' },
			{ name: 'Qinghai' },
			{ name: 'Shaanxi' },
			{ name: 'Shandong' },
			{ name: 'Shanxi' },
			{ name: 'Sichuan' },
			{ name: 'Yunnan' },
			{ name: 'Zhejiang' },
			
			// Autonomous Regions
			{ name: 'Guangxi Zhuang' },
			{ name: 'Inner Mongolia' },
			{ name: 'Ningxia Hui' },
			{ name: 'Tibet' },
			{ name: 'Xinjiang Uyghur' },
		
			// Municipalities
			{ name: 'Beijing' },
			{ name: 'Chongqing' },
			{ name: 'Shanghai' },
			{ name: 'Tianjin' },
		
			// Special Administrative Regions (SARs)
			{ name: 'Hong Kong' },
			{ name: 'Macau' }
		]
	  },
	  {
		country: 'France',
		code: '+33',
		states: [
			{ name: 'Auvergne-Rhône-Alpes' },
			{ name: 'Bourgogne-Franche-Comté' },
			{ name: 'Brittany' },
			{ name: 'Centre-Val de Loire' },
			{ name: 'Corsica' },
			{ name: 'Grand Est' },
			{ name: 'Hauts-de-France' },
			{ name: 'Île-de-France' },
			{ name: 'Normandy' },
			{ name: 'Nouvelle-Aquitaine' },
			{ name: 'Occitanie' },
			{ name: 'Pays de la Loire' },
			{ name: 'Provence-Alpes-Côte d\'Azur' },
			
			// Overseas regions
			{ name: 'Guadeloupe' },
			{ name: 'Martinique' },
			{ name: 'French Guiana' },
			{ name: 'Réunion' },
			{ name: 'Mayotte' }
		]
	  },
	  {
		country: 'Germany',
		code: '+49',
		states: [
			{ name: 'Baden-Württemberg' },
			{ name: 'Bavaria (Bayern)' },
			{ name: 'Berlin' },
			{ name: 'Brandenburg' },
			{ name: 'Bremen' },
			{ name: 'Hamburg' },
			{ name: 'Hesse (Hessen)' },
			{ name: 'Lower Saxony (Niedersachsen)' },
			{ name: 'Mecklenburg-Vorpommern' },
			{ name: 'North Rhine-Westphalia (Nordrhein-Westfalen)' },
			{ name: 'Rhineland-Palatinate (Rheinland-Pfalz)' },
			{ name: 'Saarland' },
			{ name: 'Saxony (Sachsen)' },
			{ name: 'Saxony-Anhalt (Sachsen-Anhalt)' },
			{ name: 'Schleswig-Holstein' },
			{ name: 'Thuringia (Thüringen)' }
		]
	  },
	  {
		country: 'India',
		code: '+91',
		states: [
			{ name: 'Andhra Pradesh' },
			{ name: 'Arunachal Pradesh' },
			{ name: 'Assam' },
			{ name: 'Bihar' },
			{ name: 'Chhattisgarh' },
			{ name: 'Goa' },
			{ name: 'Gujarat' },
			{ name: 'Haryana' },
			{ name: 'Himachal Pradesh' },
			{ name: 'Jharkhand' },
			{ name: 'Karnataka' },
			{ name: 'Kerala' },
			{ name: 'Madhya Pradesh' },
			{ name: 'Maharashtra' },
			{ name: 'Manipur' },
			{ name: 'Meghalaya' },
			{ name: 'Mizoram' },
			{ name: 'Nagaland' },
			{ name: 'Odisha' },
			{ name: 'Punjab' },
			{ name: 'Rajasthan' },
			{ name: 'Sikkim' },
			{ name: 'Tamil Nadu' },
			{ name: 'Telangana' },
			{ name: 'Tripura' },
			{ name: 'Uttar Pradesh' },
			{ name: 'Uttarakhand' },
			{ name: 'West Bengal' },
		
			// Union Territories
			{ name: 'Andaman and Nicobar Islands' },
			{ name: 'Chandigarh' },
			{ name: 'Dadra and Nagar Haveli and Daman and Diu' },
			{ name: 'Lakshadweep' },
			{ name: 'Delhi' },
			{ name: 'Puducherry' },
			{ name: 'Ladakh' },
			{ name: 'Jammu and Kashmir' }
		]
	  },
	  {
		country: 'Indonesia',
		code: '+62',
		states: [
			{ name: 'Aceh' },
			{ name: 'Bali' },
			{ name: 'Bangka Belitung' },
			{ name: 'Banten' },
			{ name: 'Bengkulu' },
			{ name: 'Central Java' },
			{ name: 'Central Kalimantan' },
			{ name: 'Central Sulawesi' },
			{ name: 'East Java' },
			{ name: 'East Kalimantan' },
			{ name: 'East Nusa Tenggara' },
			{ name: 'Gorontalo' },
			{ name: 'Jambi' },
			{ name: 'Lampung' },
			{ name: 'Maluku' },
			{ name: 'North Kalimantan' },
			{ name: 'North Maluku' },
			{ name: 'North Sulawesi' },
			{ name: 'North Sumatra' },
			{ name: 'Papua' },
			{ name: 'Riau' },
			{ name: 'Riau Islands' },
			{ name: 'Southeast Sulawesi' },
			{ name: 'South Kalimantan' },
			{ name: 'South Sulawesi' },
			{ name: 'South Sumatra' },
			{ name: 'West Java' },
			{ name: 'West Kalimantan' },
			{ name: 'West Nusa Tenggara' },
			{ name: 'West Papua' },
			{ name: 'West Sulawesi' },
			{ name: 'West Sumatra' },
			{ name: 'Yogyakarta' },
		
			// Special Regions
			{ name: 'Jakarta' },
			{ name: 'Yogyakarta Special Region' },
		
			// Capital Region
			{ name: 'Jakarta Capital Region' }
		]
	  },
	  {
		country: 'Italy',
		code: '+39',
		states: [
			{ name: 'Abruzzo' },
			{ name: 'Aosta Valley (Valle d\'Aosta)' },
			{ name: 'Apulia (Puglia)' },
			{ name: 'Basilicata' },
			{ name: 'Calabria' },
			{ name: 'Campania' },
			{ name: 'Emilia-Romagna' },
			{ name: 'Friuli Venezia Giulia' },
			{ name: 'Lazio' },
			{ name: 'Liguria' },
			{ name: 'Lombardy (Lombardia)' },
			{ name: 'Marche' },
			{ name: 'Molise' },
			{ name: 'Piedmont (Piemonte)' },
			{ name: 'Sardinia (Sardegna)' },
			{ name: 'Sicily (Sicilia)' },
			{ name: 'Trentino-South Tyrol (Trentino-Alto Adige)' },
			{ name: 'Tuscany (Toscana)' },
			{ name: 'Umbria' },
			{ name: 'Veneto' }
		]
	  },
	  {
		country: 'Japan',
		code: '+81',
		states: [
			{ name: 'Aichi' },
			{ name: 'Akita' },
			{ name: 'Aomori' },
			{ name: 'Chiba' },
			{ name: 'Ehime' },
			{ name: 'Fukui' },
			{ name: 'Fukuoka' },
			{ name: 'Fukushima' },
			{ name: 'Gifu' },
			{ name: 'Gunma' },
			{ name: 'Hiroshima' },
			{ name: 'Hokkaido' },
			{ name: 'Hyogo' },
			{ name: 'Ibaraki' },
			{ name: 'Ishikawa' },
			{ name: 'Iwate' },
			{ name: 'Kagawa' },
			{ name: 'Kagoshima' },
			{ name: 'Kanagawa' },
			{ name: 'Kochi' },
			{ name: 'Kumamoto' },
			{ name: 'Kyoto' },
			{ name: 'Mie' },
			{ name: 'Miyagi' },
			{ name: 'Miyazaki' },
			{ name: 'Nagano' },
			{ name: 'Nagasaki' },
			{ name: 'Nara' },
			{ name: 'Niigata' },
			{ name: 'Oita' },
			{ name: 'Okayama' },
			{ name: 'Okinawa' },
			{ name: 'Osaka' },
			{ name: 'Saga' },
			{ name: 'Saitama' },
			{ name: 'Shiga' },
			{ name: 'Shimane' },
			{ name: 'Shizuoka' },
			{ name: 'Tochigi' },
			{ name: 'Tokushima' },
			{ name: 'Tokyo' },
			{ name: 'Tottori' },
			{ name: 'Toyama' },
			{ name: 'Wakayama' },
			{ name: 'Yamagata' },
			{ name: 'Yamaguchi' },
			{ name: 'Yamanashi' }
		]
	  },
	  {
		country: 'Mexico',
		code: '+52',
		states: [
			{ name: 'Aguascalientes' },
			{ name: 'Baja California' },
			{ name: 'Baja California Sur' },
			{ name: 'Campeche' },
			{ name: 'Chiapas' },
			{ name: 'Chihuahua' },
			{ name: 'Coahuila' },
			{ name: 'Colima' },
			{ name: 'Durango' },
			{ name: 'Guanajuato' },
			{ name: 'Guerrero' },
			{ name: 'Hidalgo' },
			{ name: 'Jalisco' },
			{ name: 'Mexico State (Estado de México)' },
			{ name: 'Michoacán' },
			{ name: 'Morelos' },
			{ name: 'Nayarit' },
			{ name: 'Nuevo León' },
			{ name: 'Oaxaca' },
			{ name: 'Puebla' },
			{ name: 'Querétaro' },
			{ name: 'Quintana Roo' },
			{ name: 'San Luis Potosí' },
			{ name: 'Sinaloa' },
			{ name: 'Sonora' },
			{ name: 'Tabasco' },
			{ name: 'Tamaulipas' },
			{ name: 'Tlaxcala' },
			{ name: 'Veracruz' },
			{ name: 'Yucatán' },
			{ name: 'Zacatecas' },
			
			// Federal District
			{ name: 'Mexico City (Ciudad de México)' }
		]
	  },
	  {
		country: 'Russia',
		code: '+7',
		states: [
			{ name: 'Adygea' },
			{ name: 'Altai Republic' },
			{ name: 'Bashkortostan' },
			{ name: 'Buryatia' },
			{ name: 'Chechnya' },
			{ name: 'Chuvashia' },
			{ name: 'Dagestan' },
			{ name: 'Ingushetia' },
			{ name: 'Kabardino-Balkaria' },
			{ name: 'Kalmykia' },
			{ name: 'Karachay-Cherkessia' },
			{ name: 'Karelia' },
			{ name: 'Khakassia' },
			{ name: 'Komi' },
			{ name: 'Mari El' },
			{ name: 'Mordovia' },
			{ name: 'North Ossetia-Alania' },
			{ name: 'Sakha (Yakutia)' },
			{ name: 'Tatarstan' },
			{ name: 'Tuva' },
			{ name: 'Udmurtia' },
		
			// Krais
			{ name: 'Altai Krai' },
			{ name: 'Kamchatka Krai' },
			{ name: 'Khabarovsk Krai' },
			{ name: 'Krasnodar Krai' },
			{ name: 'Krasnoyarsk Krai' },
			{ name: 'Perm Krai' },
			{ name: 'Primorsky Krai' },
			{ name: 'Stavropol Krai' },
			{ name: 'Zabaykalsky Krai' },
		
			// Oblasts
			{ name: 'Amur Oblast' },
			{ name: 'Arkhangelsk Oblast' },
			{ name: 'Astrakhan Oblast' },
			{ name: 'Belgorod Oblast' },
			{ name: 'Bryansk Oblast' },
			{ name: 'Chelyabinsk Oblast' },
			{ name: 'Irkutsk Oblast' },
			{ name: 'Ivanovo Oblast' },
			{ name: 'Kaliningrad Oblast' },
			{ name: 'Kaluga Oblast' },
			{ name: 'Kemerovo Oblast' },
			{ name: 'Kirov Oblast' },
			{ name: 'Kostroma Oblast' },
			{ name: 'Kurgan Oblast' },
			{ name: 'Kursk Oblast' },
			{ name: 'Leningrad Oblast' },
			{ name: 'Lipetsk Oblast' },
			{ name: 'Magadan Oblast' },
			{ name: 'Moscow Oblast' },
			{ name: 'Murmansk Oblast' },
			{ name: 'Nizhny Novgorod Oblast' },
			{ name: 'Novgorod Oblast' },
			{ name: 'Novosibirsk Oblast' },
			{ name: 'Omsk Oblast' },
			{ name: 'Orel Oblast' },
			{ name: 'Orenburg Oblast' },
			{ name: 'Penza Oblast' },
			{ name: 'Pskov Oblast' },
			{ name: 'Rostov Oblast' },
			{ name: 'Ryazan Oblast' },
			{ name: 'Sakhalin Oblast' },
			{ name: 'Samara Oblast' },
			{ name: 'Saratov Oblast' },
			{ name: 'Smolensk Oblast' },
			{ name: 'Sverdlovsk Oblast' },
			{ name: 'Tambov Oblast' },
			{ name: 'Tomsk Oblast' },
			{ name: 'Tula Oblast' },
			{ name: 'Tver Oblast' },
			{ name: 'Tyumen Oblast' },
			{ name: 'Ulyanovsk Oblast' },
			{ name: 'Vladimir Oblast' },
			{ name: 'Volgograd Oblast' },
			{ name: 'Vologda Oblast' },
			{ name: 'Voronezh Oblast' },
			{ name: 'Yaroslavl Oblast' },
		
			// Federal cities
			{ name: 'Moscow' },
			{ name: 'Saint Petersburg' },
			{ name: 'Sevastopol' },
		
			// Autonomous oblast
			{ name: 'Jewish Autonomous Oblast' },
		
			// Autonomous okrugs
			{ name: 'Chukotka Autonomous Okrug' },
			{ name: 'Khanty-Mansi Autonomous Okrug' },
			{ name: 'Nenets Autonomous Okrug' },
			{ name: 'Yamalo-Nenets Autonomous Okrug' }
		]
	  },
	  {
		country: 'United Kingdom',
		code: '+44',
		states: [
		  { name: 'England' },
		  { name: 'Scotland' },
		  { name: 'Wales' },
		  { name: 'Northern Ireland' }
		]
	  }
];


export enum BusinessRegistrationType {
	'License',
    'Certificate of government'
}

export const BUSINNES_TYPE = [
	{ name: "Retail" },
	{ name: "Food & Beverage" },
	{ name: "Health & Wellness" },
	{ name: "Travel & Tourism" },
	{ name: "Technology & IT" },
	{ name: "Education & Training" },
	{ name: "Finance & Insurance" },
	{ name: "Automotive" },
	{ name: "Fashion & Apparel" },
	{ name: "Entertainment & Media" },
	{ name: "Home & Garden" },
	{ name: "Professional Services" },
	{ name: "Real Estate" },
	{ name: "Construction & Manufacturing" },
	{ name: "Sports & Fitness" },
	{ name: "Logistics & Transportation" },
	{ name: "Beauty & Personal Care" },
	{ name: "Art & Design" },
	{ name: "Pet Services" },
  ];
  
export const SERVICE_BUSINESS = [
	{ name: "General Contractors-Residential and Commercial" },
	{ name: "Heating, Plumbing, and Air Conditioning Contractors" },
	{ name: "Electrical Contractors" },
	{ name: "Masonry, Stonework, Tile Setting, Plastering and Insulation Contractors" },
	{ name: "Carpentry Contractors" },
	{ name: "Roofing, Siding, and Sheet Metal Work Contractors" },
	{ name: "Concrete Work Contractors" },
	{ name: "Special Trade Contractors" },
	{ name: "Miscellaneous Publishing and Printing" },
	{ name: "Typesetting, Plate Making and Related Services" },
	{ name: "Specialty Cleaning, Polishing and Sanitation Preparations" },
	{ name: "Affiliated Auto Rental" },
	{ name: "Taxicabs and Limousines" },
	{ name: "Bus Lines" },
	{ name: "Motor Freight Carriers and Trucking-Local and Long Distance, Moving and Storage Companies, and Local Delivery Services" },
	{ name: "Courier Services-Air and Ground, and Freight Forwarders" },
	{ name: "Public Warehousing and Storage-Farm Products, Refrigerated Goods, Household Goods, and Storage" },
	{ name: "Steamship and Cruise Lines" },
	{ name: "Boat Rentals and Leasing" },
	{ name: "Marinas, Marine Service, and Supplies" },
	{ name: "Travel Agencies and Tour Operators" },
	{ name: "Transportation Services" },
	{ name: "Telecommunication Equipment and Phone Sales" },
	{ name: "Telecommunication Services, including Local and Long Distance Calls, Credit Card Calls, Calls Through Use of Magnetic-Stripe-Reading Telephones, and Fax Services" },
	{ name: "Computer Network/Information Services" },
	{ name: "Telegraph Services" },
	{ name: "Wire Transfer Money Orders (WTMOs)" },
	{ name: "Cable, Satellite and Other Pay Television/Radio/Streaming Services" },
	{ name: "Utilities-Electric, Petrol, Water, and Sanitary" },
	{ name: "Motor Vehicle Supplies and New Parts" },
	{ name: "Office and Commercial Furniture" },
	{ name: "Construction Materials" },
	{ name: "Photographic, Photocopy, Microfilm Equipment and Supplies" },
	{ name: "Computers and Computer Peripheral Equipment and Software" },
	{ name: "Commercial Equipment" },
	{ name: "Medical, Dental, Ophthalmic and Hospital Equipment and Supplies" },
	{ name: "Metal Service Centres and Offices" },
	{ name: "Electrical Parts and Equipment" },
	{ name: "Hardware, Equipment and Supplies" },
	{ name: "Plumbing and Heating Equipment and Supplies" },
	{ name: "Industrial Supplies" },
	{ name: "Precious Stones and Metals, Watches and Jewellery" },
	{ name: "Durable Goods" },
	{ name: "Piece Goods, Notions, and Other Dry Goods" },
	{ name: "Men's, Women's, and Children's Uniforms and Commercial Clothing" },
	{ name: "Commercial Footwear" },
	{ name: "Chemicals and Allied Products" },
	{ name: "Paints, Varnishes and Supplies" },
	{ name: "Nondurable Goods" },
	{ name: "Home Supply Warehouse Stores" },
	{ name: "Lumber and Building Materials Stores" },
	{ name: "Glass, Paint, and Wallpaper Stores" },
	{ name: "Hardware Stores" },
	{ name: "Nurseries and Lawn and Garden Supply Stores" },
	{ name: "Marketplaces" },
	{ name: "Mobile Home Dealers" },
	{ name: "Discount Stores" },
	{ name: "Department Stores" },
	{ name: "Variety Stores" },
	{ name: "Miscellaneous General Merchandise" },
	{ name: "Freezer and Locker Meat Provisioners" },
	{ name: "Candy, Nut, and Confectionery Stores" },
	{ name: "Dairy Products Stores" },
	{ name: "Bakeries" },
	{ name: "Miscellaneous Food Stores-Convenience Stores and Specialty Markets" },
	{ name: "Car and Truck Dealers (New and Used) Sales, Service, Repairs, Parts, and Leasing" },
	{ name: "Car and Truck Dealers (Used Only) Sales, Service, Repairs, Parts, and Leasing" },
	{ name: "Automotive Tyre Stores" },
	{ name: "Automotive Parts and Accessories Stores" },
	{ name: "Boat Dealers" },
	{ name: "Electric Vehicle Charging" },
	{ name: "Camper, Recreational and Utility Trailer Dealers" },
	{ name: "Motorcycle Shops and Dealers" },
	{ name: "Motor Homes Dealers" },
	{ name: "Snowmobile Dealers" },
	{ name: "Miscellaneous Automotive, Aircraft, and Farm Equipment Dealers" },
	{ name: "Men's and Boys' Clothing and Accessories Stores" },
	{ name: "Women's Ready-To-Wear Stores" },
	{ name: "Women's Accessory and Specialty Shops" },
	{ name: "Children's and Infants' Wear Stores" },
	{ name: "Family Clothing Stores" },
	{ name: "Sports and Riding Clothing Stores" },
	{ name: "Shoe Stores" },
	{ name: "Men's and Women's Clothing Stores" },
	{ name: "Tailors, Seamstresses, Mending, and Alterations" },
	{ name: "Wig and Toupee Stores" },
	{ name: "Miscellaneous Clothing and Accessory Shops" },
	{ name: "Furniture, Home Furnishings, and Equipment Stores, Except Appliances" },
	{ name: "Floor Covering Stores" },
	{ name: "Drapery, Window Covering, and Upholstery Stores" },
	{ name: "Fireplace, Fireplace Screens and Accessories Stores" },
	{ name: "Miscellaneous Home Furnishing Specialty Stores" },
	{ name: "Household Appliance Stores" },
	{ name: "Electronics Stores" },
	{ name: "Music Stores-Musical Instruments, Pianos, and Sheet Music" },
	{ name: "Computer Software Stores" },
	{ name: "Record Stores" },
	{ name: "Caterers" },
	{ name: "Eating Places and Restaurants" },
	{ name: "Fast Food Restaurants" },
	{ name: "Digital Goods Media-Books, Movies, Music" },
	{ name: "Digital Goods-Games" },
	{ name: "Digital Goods-Applications (Excludes Games)" },
	{ name: "Drug Stores and Pharmacies" },
	{ name: "Package Stores-Beer, Wine, and Liquor" },
	{ name: "Used Merchandise and Secondhand Stores" },
	{ name: "Antique Shops-Sales, Repairs, and Restoration Services" },
	{ name: "Wrecking and Salvage Yards" },
	{ name: "Bicycle Shops-Sales and Service" },
	{ name: "Sporting Goods Stores" },
	{ name: "Bookshops" },
	{ name: "Stationery Stores, Office and School Supply Stores" },
	{ name: "Hobby, Toy, and Game Shops" },
	{ name: "Camera and Photographic Supply Stores" },
	{ name: "Gift, Card, Novelty and Souvenir Shops" },
	{ name: "Luggage and Leather Goods Stores" },
	{ name: "Sewing, Needlework, Fabric and Piece Goods Stores" },
	{ name: "Glassware/Crystal Stores" },
	{ name: "Direct Marketing-Insurance Services" },
	{ name: "Direct Marketing-Inbound Teleservices Merchant" },
	{ name: "Direct Marketing-Other Direct Marketers" },
	{ name: "Artist's Supply and Craft Shops" },
	{ name: "Art Dealers and Galleries" },
	{ name: "Stamp and Coin Stores" },
	{ name: "Religious Goods Stores" },
	{ name: "Hearing Aids-Sales, Service, and Supply" },
	{ name: "Orthopaedic Goods-Prosthetic Devices" },
	{ name: "Cosmetic Stores" },
	{ name: "Typewriter Stores-Sales, Rentals, and Service" },
	{ name: "Florists" },
	{ name: "Cigar Stores and Stands" },
	{ name: "News Dealers and Newsstands" },
	{ name: "Pet Shops, Pet Foods and Supplies Stores" },
	{ name: "Swimming Pools-Sales and Service" },
	{ name: "Electric Razor Stores-Sales and Service" },
	{ name: "Tent and Awning Shops" },
	{ name: "Miscellaneous and Specialty Retail Shops" },
	{ name: "Financial Institutions-Merchandise and Services" },
	{ name: "Non-Financial Institutions-Foreign Currency, Money Orders (Not Wire Transfer), and Travellers Cheques" },
	{ name: "Security Brokers/Dealers" },
	{ name: "Insurance Sales, Underwriting, and Premiums" },
	{ name: "Real Estate Agents and Managers-Rentals" },
	{ name: "Lodging-Hotels, Motels, Resorts, Central Reservation Services" },
	{ name: "Timeshares" },
	{ name: "Sporting and Recreational Camps" },
	{ name: "Trailer Parks and Camping Grounds" },
	{ name: "Laundromat, Cleaning, and Garment Services" },
	{ name: "Laundries-Family and Commercial" },
	{ name: "Dry Cleaners" },
	{ name: "Carpet and Upholstery Cleaning" },
	{ name: "Photographic Studios" },
	{ name: "Beauty and Barber Shops" },
	{ name: "Shoe Repair Shops, Shoe Shine Parlours, and Hat Cleaning Shops" },
	{ name: "Dating Services" },
	{ name: "Counselling Services-Debt, Marriage, and Personal" },
	{ name: "Buying and Shopping Services and Clubs" },
	{ name: "Clothing Rental-Costumes, Uniforms, Formal Wear" },
	{ name: "Health and Beauty Spas" },
	{ name: "Miscellaneous Personal Services" },
	{ name: "Advertising Services" },
	{ name: "Consumer Credit Reporting Agencies" },
	{ name: "Commercial Photography, Art, and Graphics" },
	{ name: "Quick Copy, Reproduction, and Blueprinting Services" },
	{ name: "Stenographic and Secretarial Support" },
	{ name: "Exterminating and Disinfecting Services" },
	{ name: "Cleaning, Maintenance, and Janitorial Services" },
	{ name: "Employment Agencies and Temporary Help Services" },
	{ name: "Computer Programming, Data Processing, and Integrated Systems Design Services" },
	{ name: "Information Retrieval Services" },
	{ name: "Computer Maintenance, Repair and Services" },
	{ name: "Management, Consulting, and Public Relations Services" },
	{ name: "Detective Agencies, Protective Services, and Security Services, including Armored Cars, and Guard Dogs" },
	{ name: "Equipment, Tool, Furniture, and Appliance Rental and Leasing" },
	{ name: "Photofinishing Laboratories and Photo Developing" },
	{ name: "Business services" },
	{ name: "Car hire agency" },
	{ name: "Truck and Utility Trailer Rentals" },
	{ name: "Motor Home and Recreational Vehicle Rentals" },
	{ name: "Car Parks, Parking Meters and Garages" },
	{ name: "Automotive Body Repair Shops" },
	{ name: "Tyre Retreading and Repair Shops" },
	{ name: "Automotive Paint Shops" },
	{ name: "Automotive Service Shops (Non-Dealer)" },
	{ name: "Car Washes" },
	{ name: "Towing Services" },
	{ name: "Electronics Repair Shops" },
	{ name: "Air Conditioning and Refrigeration Repair Shops" },
	{ name: "Electrical and Small Appliance Repair Shops" },
	{ name: "Watch, Clock and Jewellery Repair" },
	{ name: "Furniture-Reupholstery, Repair, and Refinishing" },
	{ name: "Welding Services" },
	{ name: "Miscellaneous Repair Shops and Related Services" },
	{ name: "Motion Picture and Video Tape Production and Distribution" },
	{ name: "Motion Picture Theatres" },
	{ name: "Dance Halls, Studios and Schools" },
	{ name: "Theatrical Producers (Except Motion Pictures) and Ticket Agencies" },
	{ name: "Bands, Orchestras, and Miscellaneous Entertainers" },
	{ name: "Billiard and Pool Establishments" },
	{ name: "Bowling Alleys" },
	{ name: "Commercial Sports, Professional Sports Clubs, Athletic Fields, and Sports Promoters" },
	{ name: "Tourist Attractions and Exhibits" },
	{ name: "Public Golf Courses" },
	{ name: "Video Amusement Game Supplies" },
	{ name: "Video Game Arcades/Establishments" },
	{ name: "Betting, including Lottery Tickets, Casino Gaming Chips, Off-Track Betting, and Wagers at Race Tracks" },
	{ name: "Amusement parks, circuses, carnivals and fortune tellers" },
	{ name: "Membership Clubs (Sports, Recreation, Athletic), Country Clubs, and Private Golf Courses" },
	{ name: "Aquariums, Seaquariums, Dolphinariums, and Zoos" },
	{ name: "Recreation Services" },
	{ name: "Dentists and Orthodontists" },
	{ name: "Opticians, Optical Goods, and Eyeglasses" },
	{ name: "Medical Services and Health Practitioners" },
	{ name: "Legal Services and Lawyers" },
	{ name: "Elementary and Secondary Schools" },
	{ name: "Colleges, Universities, Professional Schools, and Junior Colleges" },
	{ name: "Correspondence Schools" },
	{ name: "Business and Secretarial Schools" },
	{ name: "Vocational and Trade Schools" },
	{ name: "Schools and Educational Services" },
	{ name: "Child Care Services" },
	{ name: "Charitable and Social Service Organisations" },
	{ name: "Civic, Social, and Fraternal Associations" },
	{ name: "Political Organisations" },
	{ name: "Religious Organisations" },
	{ name: "Car Associations" },
	{ name: "Membership Organisations" },
	{ name: "Architectural, Engineering, and Surveying Services" },
	{ name: "Accounting, Auditing, and Bookkeeping Services" },
	{ name: "Professional Services" },
	{ name: "Government Services" },
	{ name: "Veterinary Services" },
	{ name: "Agricultural Co-operatives" },
	{ name: "Landscaping and Horticultural Services" }
];
export const INCOME_12 = [
	{ name: "Too early to say" },
	{ name: "$0.00 - $20,000.00" },
	{ name: "$20,000.00 - $150,000.00" },
	{ name: "$150,000.00 - $500,000.00" },
	{ name: "$500,000.00 - $1,000,000.00" },
	{ name: "$1,000,000.00 & above" },

];
